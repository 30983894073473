<footer>
    <div class="container">
      <div class="flex-container justify row">
        <div>
          <p>Bright PDF © 2024</p>
        </div>
        <div>
          <ul>
            <li><a target="_blank" href="https://www.bright-pdf.com/conditions">EULA</a></li>
            <li><a target="_blank" href="https://www.bright-pdf.com/policy">Privacy</a></li>
            <li><a target="_blank" href="https://www.bright-pdf.com/uninstall">Uninstall</a></li>
            <li><a target="_blank" href="https://www.bright-pdf.com/contact">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
